<template>
  <PromotionForm 
    :promotion="promotion"
    @savePromotion="updatePromotion"
    :formType="formType"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import PromotionForm from '@/components/PromotionForm.vue'
import server from '../api'

export default {
    name: 'EditPromotion',
    components: {
        PromotionForm
    },
    data () {
        return {
            formType: {
                text: 'Edit',
                value: 'edit'
            },
            buttonLoading: false
        }
    },
    methods: {
        updatePromotion() {
            this.buttonLoading = !this.buttonLoading;
            let data = new FormData();
            
            data.append("platform", this.promotion.platform)
            data.append("promoName", this.promotion.promoName)
            data.append("promoType", this.promotion.promoType)
            data.append("amount", this.promotion.amount)
            data.append("minTransaction", this.promotion.minTransaction)
            data.append("maxAmount", this.promotion.maxAmount)
            data.append("Quota", this.promotion.Quota)
            data.append("usedQuota", this.promotion.usedQuota)
            data.append("promoPhoto", this.promotion.promoImage)
            data.append("promoStatus", this.promotion.promoStatus)
            data.append("valid_until", this.promotion.valid_until)
            data.append("valid_from", this.promotion.valid_from)
            data.append("terms", this.promotion.terms)
            data.append("promoCondition", this.promotion.promoCondition)
            data.append("promoValue", this.promotion.promoValue)
            data.append("promotionDefault", this.promotion.promotionDefault)
            data.append("description", this.promotion.description)
            data.append("rule", this.promotion.rule)
            data.append("promoCode", this.promotion.promoCode)

            server.put(`/promotion/${this.promotion.id}`, data, {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$router.push({ name: 'Promotion' });
                    this.$store.commit('SET_SUCCESS', `Berhasil Update Promotion ${result.data.Promotion.promoName}`);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    if(err.response.data.errors.length) {
                        this.$store.commit('SET_ERROR', err.response.data.errors[0].message);
                    } else {
                        this.$store.commit('SET_ERROR', err.response.data.errors.message);
                    }
                });
        }
    },
    computed: {
        promotion () {
            return this.$store.state.promotion
        }
    },
    created () {
        this.$store.dispatch('getPromotionById', { id: this.$route.params.id });
    }
}
</script>

<style>

</style>