<template>
    <v-dialog v-model="detail_order" @click:outside="closeDetail">
        <v-card>
            <v-card-title>
                Tester Details {{ order.id }}
                <v-spacer></v-spacer>
                <v-chip color="primary">
                    {{ order.status }}
                </v-chip>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="justify-space-around">
                        <v-col cols="3">
                            <h4 class="text--futura">Date</h4><br>
                            <span class="text--futura">
                                {{ formatDate(order.createdAt) }}
                            </span>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Recipient</h4><br>
                            <span class="text--futura" v-if="order.Customer">
                                {{ order.Customer.firstName }} {{ order.Customer.middleName ? order.Customer.middleName : ''
                                }} {{ order.Customer.lastName }}
                            </span>
                            <span class="text--futura" v-else-if="order.Vendor">
                                {{ order.Vendor.company_name }}
                            </span>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Contact</h4><br>
                            <span class="text--futura" v-if="order.Customer">
                                {{ order.Customer.CustomerContact.contactNo }}
                            </span>
                            <span class="text--futura" v-else-if="order.Vendor">
                                {{ order.Vendor.VendorContact.phone_number }}
                            </span>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Destination</h4><br>
                            <span class="text--futura">
                                {{ `${order.ShipFromLocation.LocationName} - ${order.ShiptoAddress1}
                                                                (${order.City.postal_code})` }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <h4 class="text--futura">Product</h4>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Quantity</h4>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Price Each</h4>
                        </v-col>
                        <v-col cols="3">
                            <h4 class="text--futura">Price</h4>
                        </v-col>
                    </v-row>
                    <hr>
                    <v-row v-for="product in order.TesterLine.items" :key="product.id">
                        <v-col cols="3">
                            <span class="text--futura">{{ product.Item.name }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="text--futura">{{ product.qty }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="text--futura">{{ formatHarga(0) }}</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="text--futura">{{ formatHarga(sub_total(0, 0)) }}</span>
                        </v-col>
                    </v-row>
                    <hr>
                    <hr>
                    <v-row class="justify-space-around">
                        <v-col cols="4">
                            <h4 class="text--futura">Shipment Address</h4><br>
                            <span class="text--futura">
                                {{ order.ShiptoAddress1 }}
                            </span>
                        </v-col>
                        <v-col cols="4">
                            <h4 class="text--futura">Ship By</h4><br>
                            <span class="text--futura">
                                {{ order.DeliveryMethod.CourierCompany }}
                            </span>
                        </v-col>
                        <v-col cols="4">
                            <h4 class="text--futura">Purpose</h4><br>
                            <span class="text--futura">
                                {{ capitalizeFirstLetter(order.purpose) }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="justify-space-around">
                        <v-col cols="3">
                            <h4 class="text--futura">Status</h4><br>
                            <v-select :disabled="order.status != 'Pending'" solo label="Status" :items="tester_statuses" v-model="order.status">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row class="justify-space-between mt-12">
                        <div>
                            <v-btn :disabled="order.status != 'Pending'" class="text--futura text-capitalize mr-3" @click="updateStatus('Accepted')">
                                Accepted
                            </v-btn>
                            <v-btn :disabled="order.status != 'Pending'" class="text--futura text-capitalize mr-3" @click="updateStatus('Pending')">
                                Pending
                            </v-btn>
                            <v-btn :disabled="order.status != 'Pending'" class="text--futura text-capitalize mr-3" @click="updateStatus('Rejected')">
                                Rejected
                            </v-btn>
                        </div>
                        <div>
                            <v-btn class="text--futura text-capitalize mr-3" @click="closeDetail">
                                Cancel
                            </v-btn>
                            <v-btn dark class="text--futura text-capitalize mr-3" @click.prevent="updateTester(order.id)">
                                Save
                            </v-btn>
                        </div>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'DetailTester',
    props: {
        detail_order: {
            type: Boolean,
            default: false
        },
        order: {
            type: Object
        }
    },
    computed: {
        tester_statuses() {
            return this.$store.state.tester_statuses;
        },
        order_statuses() {
            return this.$store.state.order_statuses;
        },
        OrderStatus: {
            get: function () {
                return this.order.status
            },
            set: function (value) {
                this.order.status = value
            }
        },
        tracking_code: {
            get: function () {
                return this.order.tracking_code
            },
            set: function (value) {
                this.order.tracking_code = value
            }
        },
        ShipStatus: {
            get: function () {
                return this.order.ShipStatus
            },
            set: function (value) {
                this.order.ShipStatus = value
            }
        },
        couriers() {
            return this.$store.state.couriers
        }
    },
    methods: {
        closeDetail() {
            this.$emit('closeDetail');
        },
        sub_total(price, qty) {
            return price * qty;
        },
        updateTester(id) {
            this.$emit('updateTester', id);
        },
        updateStatus(status) {
            this.order.status = status
            this.updateTester(this.order.id)
        }
    },
    created() {
        this.$store.dispatch('getCouriers');
    }

}
</script>

<style></style>