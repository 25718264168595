<template>
  <PromotionForm 
    :promotion="promotion"
    @savePromotion="savePromotion"
    :buttonLoading="buttonLoading"
  />
</template>

<script>
import PromotionForm from '@/components/PromotionForm'
import server from '../api'

export default {
    name: 'AddPromotion',
    components: {
        PromotionForm
    },
    data () {
        return {
            promotion: {
                valid_from: new Date().toISOString().substr(0, 10),
                valid_until: new Date().toISOString().substr(0,10),
                promoType: '',
                promoName: '',
                promoCode: '',
                promoStatus: 1,
                platform: '',
                terms: '',
                promoCondition: '',
                amount: 0,
                maxAmount: 0,
                Quota: 0,
                usedQuota: 0,
                minTransaction: 0,
                promoValue: 0,
                promotionDefault: false,
                description:"",
                rule:"",
                promoImage: ''
            },
            buttonLoading: false
        }
    },
    methods: {
        savePromotion () {
            this.buttonLoading = !this.buttonLoading;
            let data = new FormData();
            
            data.append("platform", this.promotion.platform)
            data.append("promoName", this.promotion.promoName)
            data.append("promoType", this.promotion.promoType)
            data.append("amount", this.promotion.amount)
            data.append("minTransaction", this.promotion.minTransaction)
            data.append("maxAmount", this.promotion.maxAmount)
            data.append("Quota", this.promotion.Quota)
            data.append("usedQuota", this.promotion.usedQuota)
            data.append("promoPhoto", this.promotion.promoImage)
            data.append("promoStatus", this.promotion.promoStatus)
            data.append("valid_until", this.promotion.valid_until)
            data.append("valid_from", this.promotion.valid_from)
            data.append("terms", this.promotion.terms)
            data.append("promoCondition", this.promotion.promoCondition)
            data.append("promoValue", this.promotion.promoValue)
            data.append("promotionDefault", this.promotion.promotionDefault)
            data.append("description", this.promotion.description)
            data.append("rule", this.promotion.rule)
            data.append("promoCode", this.promotion.promoCode)

            server.post('/promotion', data , {
                headers: {
                    token: localStorage.token
                }
            })
                .then(result => {
                    this.buttonLoading = !this.buttonLoading;
                    this.$store.commit('SET_SUCCESS', `Berhasil Menambahkan promotion ${result.data.Promotion.promoName}`)
                    this.$router.go(-1);
                })
                .catch(err => {
                    this.buttonLoading = !this.buttonLoading;
                    if(err.response.data.errors.code === 409) {
                        this.$store.commit('SET_ERROR', err.response.data.errors.message);
                    }else {
                        this.$store.commit('SET_ERROR', err);
                    }
                })
        }
    }
}
</script>

<style>
</style>